export abstract class Environment {
	// public abstract read mode?: "string";
	public abstract production = false;
	public abstract readonly Mode: string;
	public abstract readonly APIUrl: string;
	public abstract readonly WebUrl: string;
	public abstract readonly EComHost: string;
	public abstract readonly APIDistributionCore: string;
	public abstract readonly APIComsumerKey: string;
	public abstract readonly APIUserName: string;
	public abstract readonly APIPassword: string;
	public abstract readonly PayGatewayUrl: string;
	public abstract readonly EnableMatomo: boolean;
	public abstract readonly MatomoCfg;
	public readonly MatomoBannerIds = {
		bigy: 30,
	};

	// public abstract readonly SenderId : string;
	public readonly MapZoom = 7;
	public readonly AppVersion = '6.0.8';
	public readonly AppName = 'my Bigy';
	public readonly ProductName = 'bigy';
	public readonly CompanyId = '';
	public readonly ApplicationId = '';
	public readonly AppBundle = "sysnify.com.bigyrelationshop";


	public readonly BuildNumber = '6080000';
	public readonly TrackingCode = 'BigYMobile';
	public readonly HeaderToken = 'X-MCMAccessToken';
	public readonly DefaultHeaders = { HeaderToken: "", 'Content-Type': 'application/json', "TrackingCode": this.TrackingCode };
	public readonly DefaultBanerId = 30;
	public readonly DefaultBanerExpressId = 31;
	public readonly DefaultBanerName = 'bigy';
	public readonly DefaultImg: string = 'https://unitedcloud.relationshop.net/RSData/DefaultMissingImage.jpg';
	public readonly gMapAPI_KEY = 'AIzaSyDlpY2a4MFOWs8I3tHlfSMk0nviZG6ecBo';// android: AIzaSyCQBKl2z9UVHxW0f7EUogciv6hoz_PnzJ8
	public readonly loadingImgUrl = 'assets/img/loader.gif';
	public readonly errorImgUrl = '';
	public DeviceMode = 'Mobile';

	public readonly dateFormat = 'MM/DD/YY';
	public readonly URLSCHEME = 'rsbigy';

	//Links
	public readonly links = {
		supportContact: 'https://www.bigy.com/myBigYsupport',
		locations: 'https://www.bigyexpress.com/Locations',
		tc: 'https://www.bigy.com/TermsConditions',
		privacy: 'https://www.bigy.com//Services/Policies'
	};


	/* Online Ordering */
	public abstract readonly EComCoreHost: string;
	public abstract readonly EComBagAPIURL: string;
	public abstract readonly EComOrderAPIURL: string;
	public abstract readonly EComProductAPIURL: string;
	public abstract readonly EComStoreAPIURL: string;
	public abstract readonly SmartConnectPickupAPIURL: string;
	public abstract readonly SCAdsAPIUrl: string;
	public abstract readonly WeeklyAdAPIUrl: string;
	public abstract readonly CMSUrl: string;
	public abstract readonly ScriptUrl: string;
	public DxpComponentBuildNumber: string;

	// Enable E-com system service
	public readonly ScanAndGoSvcId: number;
	public readonly OnlineOrderSvcId: number
	public readonly CateringSvcId: number;
	public readonly PartyTraysSvcId: number;
	public readonly GiftCardsSvcId: number;
	public readonly CustomCakesSvcId: number;
	public readonly TakeOutSvcId: number;
	public readonly MakeAListSvcId: number;

	public abstract readonly VersionStoreAPI: string;
	public abstract readonly VersionOrderAPI: string;
	public abstract readonly VersionIdentityAPI: string;

	// Enable E-com system service
	public readonly ScanAndGoEnabled = true;
	public readonly OnlineOrderEnabled = true;
	public readonly DefaultPickingSlotDayRange = 7; // 1 week
	public readonly LimitPickingSlotTime = 1; // 1 month

	/* Online Ordering */
	public readonly UComApiKey: string;
	public readonly PaymentProvider: string;
	public readonly HostedpagePaymentProvider: string;


	public readonly AppTimeZone = 'America/Chicago';


	/* Store */
	public readonly storeModuleCfg = {
		states: ['CI', 'MA']

	}
	/* End Store*/
	public readonly PharmacyAppUrl = 'cflbigy://open/';
	public readonly PharmacyPackageName = 'com.familyhealthnetwork.cfl.bigy';
	public readonly AppStorePharmacyAppUrl = 'https://apps.apple.com/us/app/id1514423956';
	public readonly GooglePlayPharmacyAppUrl = 'https://play.google.com/store/apps/details?id=com.familyhealthnetwork.cfl.bigy';
}