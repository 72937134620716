import { Injectable } from '@angular/core';
// import 'rxjs/add/operator/toPromise';
import { LoadingController, ToastController, AlertController, NavController, ModalController, Platform, IonicSafeString } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { CacheService } from 'ionic-cache';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { interval, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import _ from 'lodash';
import { ENV } from '@app/env';
import { RelationshopHttpClient } from '@rsApp/modules/gateway/rs-api.service';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { Device } from '@ionic-native/device/ngx';
import { IframeModal } from '../components/iframe-modal/iframe-modal';
import { Storage } from '@ionic/storage';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Router } from '@angular/router';
import { AppSettings } from './app-setting';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { GuestMsg } from '../components/guest-msg/guest-msg';
import { RelationshopEComCoreHttpClient } from '@rsApp/modules/gateway/rs-ecom-core-api.service';
@Injectable({
	providedIn: 'root'
})
export class Utils {
	defaultImg = ENV.DefaultImg;
	loading: any;
	currentAlert: HTMLIonAlertElement;
	// currentRequestPermissions: Promise<any>;
	runOnceRequestPermissions: boolean = false;
	runOnceAlert: Promise<any>;
	_showWellCome = true;
	_store: any; π
	_blur: boolean;
	_countDownClock: any;
	_hours: any;
	_minutes: any;
	_seconds: any;
	_countDownObs: any;
	public swipeBackEnabled = true;

	statelist = [
		{ StateName: 'Alabama', StateId: 'AL' },
		{ StateName: 'Alaska', StateId: 'AK' },
		{ StateName: 'Arizona', StateId: 'AZ' },
		{ StateName: 'Arkansas', StateId: 'AR' },
		{ StateName: 'California', StateId: 'CA' },
		{ StateName: 'Colorado', StateId: 'CO' },
		{ StateName: 'Connecticut', StateId: 'CT' },
		{ StateName: 'Delaware', StateId: 'DE' },
		{ StateName: 'District Of Columbia', StateId: 'DC' },
		{ StateName: 'Florida', StateId: 'FL' },
		{ StateName: 'Georgia', StateId: 'GA' },
		{ StateName: 'Hawaii', StateId: 'HI' },
		{ StateName: 'Idaho', StateId: 'ID' },
		{ StateName: 'Illinois', StateId: 'IL' },
		{ StateName: 'Indiana', StateId: 'IN' },
		{ StateName: 'Iowa', StateId: 'IA' },
		{ StateName: 'Kansas', StateId: 'KS' },
		{ StateName: 'Kentucky', StateId: 'KY' },
		{ StateName: 'Louisiana', StateId: 'LA' },
		{ StateName: 'Maine', StateId: 'ME' },
		{ StateName: 'Maryland', StateId: 'MD' },
		{ StateName: 'Massachusetts', StateId: 'MA' },
		{ StateName: 'Michigan', StateId: 'MI' },
		{ StateName: 'Minnesota', StateId: 'MN' },
		{ StateName: 'Mississippi', StateId: 'MS' },
		{ StateName: 'Missouri', StateId: 'MO' },
		{ StateName: 'Montana', StateId: 'MT' },
		{ StateName: 'Nebraska', StateId: 'NE' },
		{ StateName: 'Nevada', StateId: 'NV' },
		{ StateName: 'New Hampshire', StateId: 'NH' },
		{ StateName: 'New Jersey', StateId: 'NJ' },
		{ StateName: 'New Mexico', StateId: 'NM' },
		{ StateName: 'New York', StateId: 'NY' },
		{ StateName: 'North Carolina', StateId: 'NC' },
		{ StateName: 'North Dakota', StateId: 'ND' },
		{ StateName: 'Ohio', StateId: 'OH' },
		{ StateName: 'Oklahoma', StateId: 'OK' },
		{ StateName: 'Oregon', StateId: 'OR' },
		{ StateName: 'Pennsylvania', StateId: 'PA' },
		{ StateName: 'Rhode Island', StateId: 'RI' },
		{ StateName: 'South Carolina', StateId: 'SC' },
		{ StateName: 'South Dakota', StateId: 'SD' },
		{ StateName: 'Tennessee', StateId: 'TN' },
		{ StateName: 'Texas', StateId: 'TX' },
		{ StateName: 'Utah', StateId: 'UT' },
		{ StateName: 'Vermont', StateId: 'VT' },
		{ StateName: 'Virginia', StateId: 'VA' },
		{ StateName: 'Washington', StateId: 'WA' },
		{ StateName: 'West Virginia', StateId: 'WV' },
		{ StateName: 'Wisconsin', StateId: 'WI' },
		{ StateName: 'Wyoming', StateId: 'WY' }
	];

	cityList = [
		{ City: "Abilene", State: "TX" },
		{ City: "Alamogordo", State: "NM" },
		{ City: "Albuquerque", State: "NM" },
		{ City: "Allen", State: "TX" },
		{ City: "Amarillo", State: "TX" },
		{ City: "Borger", State: "TX" },
		{ City: "Breckenridge", State: "TX" },
		{ City: "Brownfield", State: "TX" },
		{ City: "Brownwood", State: "TX" },
		{ City: "Burkburnett", State: "TX" },
		{ City: "Canyon", State: "TX" },
	];

	featureList = [
		{ text: 'Coffee Shop', value: 'CS' },
		{ text: 'Pharmacy', value: 'P' },
		{ text: 'Fuel', value: 'F' },
		{ text: 'In-store Dining', value: 'ID' },
		{ text: 'Floral', value: 'Fl' },
		{ text: 'Bakery', value: 'B' },
		{ text: 'In-store Bar', value: 'IB' },
		{ text: 'Clear Talk', value: 'CT' },
		{ text: 'Deli', value: 'D' },
		{ text: 'Meat', value: 'M' },
		{ text: 'Red Box', value: 'RB' },
		{ text: 'Bill Pay', value: 'BP' },
		{ text: 'Lottert', value: 'L' }
	];
	offlineMsg = "This page is temporarily unavailable. Please refresh and try again.";

	urlParsingNode = document.createElement("a");

	lowercase = function (string) { return typeof (string) === 'string' ? string.toLowerCase() : string; };
	msie: any;
	constructor(public loadingCtrl: LoadingController,
		private toastCtrl: ToastController,
		public iab: InAppBrowser,
		public alertCtrl: AlertController,
		public cache: CacheService,
		private geolocation: Geolocation,
		// public events: Events,
		public navCtrl: NavController,
		public api: RelationshopHttpClient,
		public ecomApi: RelationshopEComCoreHttpClient,
		private device: Device,
		private platform: Platform,
		private storage: Storage,
		private diagnostic: Diagnostic,
		public modalController: ModalController,
		public router: Router,
		public openNativeSettings: OpenNativeSettings,
		public appSettings: AppSettings
		/*public shoppingList: ShoppingList*/) {
		this.msie = parseInt((/msie (\d+)/.exec(this.lowercase(navigator.userAgent)) || [])[1], 10);
		if (isNaN(this.msie)) {
			this.msie = parseInt((/trident\/.*; rv:(\d+)/.exec(this.lowercase(navigator.userAgent)) || [])[1], 10);
		}
	}
	navigateRoot(backRoute, isBack = false) {
		const options: NavigationOptions = {
			animated: isBack || false,
			animationDirection: 'back'
		};
		this.navCtrl.navigateRoot(decodeURIComponent(backRoute), options);
	}
	getCities() {
		// let seq = this.restangular.all('cities').customGET().share();
		let seq = this.api.get('/cities', { params: { rCache: 'd', rCacheKey: 'cities' } }).pipe(
			map((res: any) => {
				if (res.length == 0) {
					res = this.cityList;
				}
				this.setCache('cities', res);
				return res;
			}, err => {
				console.error('ERROR', err);
			})
		);

		return seq;
	}

	public getOSName() {
		return this.device.platform;
	}
	public validateEmail(elementValue) {
		var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		return emailPattern.test(elementValue);
	}

	clearBadge(token) {
		return this.ecomApi.post(`${ENV.APIDistributionCore}/Firebase/ClearBadge?deviceToken=${token}`, null)
			.pipe(
				catchError((error) => {
					console.error(error);
					return of(null);
				})
			);
	}

	getCityArea() {
		let seq = this.api.get('/cityarea/items', { params: { rCache: 'd', rCacheKey: 'city-area' } }).pipe(
			map((res: any) => {
				// if (res.length == 0) {
				// 	res = this.cityList;
				// }
				return res;
			}, err => {
			})
		);
		return seq;
	}

	getStates() {
		let seq = this.api.get('/states', { params: { rCache: 'd', rCacheKey: 'states' } }).pipe(
			map((res: any) => {
				if (res.length == 0) {
					res = this.statelist;
				}
				return res;
			}, err => {
				console.error('ERROR', err);
			})
		);

		return seq;
	}

	getStore() {
		return this._store;
	}

	setStore(store) {
		this._store = store;
	}

	async showLoading() {
		try {
			if (!this.loading) {
				this.loading = await this.loadingCtrl.create({
					spinner: null,
					message: `<div class="cssload-container">
				  	<img class="tab-img" src="assets/img/loadding-img.gif"/>
				  	</div>`,
					// cssClass: 'loader',
					duration: 10000,
					// dismissOnPageChange: true
				});
				this.loading.present();
				this.loading.onDidDismiss().then(() => {
					this.loading = null;
				});
			}
		}
		catch (e) {
			console.log(e)
		}
	}

	async hideLoading() {
		// if (!!this.loading)
		// 	this.loading.dismiss();
		// setTimeout(()=>{
		try {
			if (this.loading) {
				await this.loading.dismiss();
				this.loading = null;
			}
		}
		catch (e) {
			console.log('DDDDDHIDE LOADING error', e)
		}
		// }, 100);
	}


	getCache(name) {
		var key = 'cache-' + name;
		return this.cache.getItem(key)
			.catch(() => {
				console.log("Cache expired or doesn't exist!");
			});
	}

	setCache(name, data) {
		this.cache.saveItem('cache-' + name, data);
	}

	async checkPermissionLocation(isShowCustomMessage?) {
		return await new Promise(async (resolve, reject) => {
			let isCanAccess = await this.diagnostic.getLocationAuthorizationStatus();
			if (isShowCustomMessage && (isCanAccess == this.diagnostic.permissionStatus.DENIED_ONCE || isCanAccess == this.diagnostic.permissionStatus.NOT_REQUESTED)) {
				await this.requestPermissions();
				resolve("REQUESTED_PERMISSION")
			} else {
				resolve(isCanAccess)
			}
		})
	}
	private async requestPermissions() {
		if (this.currentAlert) {
			await this.currentAlert.onDidDismiss();
		}
		await this.loading && this.loading.dismiss() || Promise.resolve(true);
		this.currentAlert = await this.alertOk("   ", "Allow Big Y to access this device's location to quickly display stores near you?");

		this.runOnceRequestPermissions = true;
		await this.hideLoading();
		await this.currentAlert.onDidDismiss();
		this.currentAlert = null;
	}
	async getCurrentPosition(timeout = 5000, isShowCustomMessage?) {
		let data: any = {};
		try {
			// if (this.platform.is('cordova') && this.platform.is("android")) {
			// Big Y disable
			// isShowCustomMessage = isShowCustomMessage || !this.runOnceRequestPermissions; //runOnceRequestPermissions is first launched app
			// let resultAccess = await this.checkPermissionLocation(isShowCustomMessage);
			// if (!isShowCustomMessage && resultAccess == this.diagnostic.permissionStatus.NOT_REQUESTED && resultAccess != "REQUESTED_PERMISSION") {
			// 	data = {
			// 		isLocationOn: false,
			// 		error: 'DENIED'
			// 	}
			// 	return data;
			// }
			// }
			let resp = await this.geolocation.getCurrentPosition({ timeout: timeout });

			if (resp) {
				data = {
					isLocationOn: true,
					lat: resp.coords.latitude,
					lng: resp.coords.longitude
				}
			}
			return data;
		} catch (error) {
			console.log('error', timeout, error);
			data = {
				isLocationOn: false,
				error: error
			}
			return data;
		}


	}

	groupBy(data, property) {
		const result = data.reduce((previous, current) => {
			if (!previous[current[property]]) {
				previous[current[property]] = [current];
			} else {
				previous[current[property]].push(current);
			}
			return previous;
		}, {});

		return Object.keys(result).map(key => ({ key, value: result[key] }));
	}

	str2date(str) {
		if (typeof (str) !== 'string') {
			return str;
		}
		str = str ? str.substr(0, 10) : str;
		if (!str) {
			return str;
		}
		var strs = str.split('-');
		return new Date(strs[0], parseInt(strs[1], 10) - 1, strs[2]);
	}
	async toast(message, params?) {
		let toast = await this.toastCtrl.create({
			message: message,
			duration: 4000,
			position: params && params.position || 'bottom',
			// showCloseButton: true,
			// closeButtonText: " X",
			// dismissOnPageChange: true
			buttons: [

			]
		});
		toast.present();
	}
	async confirmYesNo(title, message, callBack) {
		let selected = false;
		let confirm = await this.alertCtrl.create({
			header: title,
			message: message,
			buttons: [
				{
					text: 'YES',
					handler: () => {
						selected = true;
						callBack(true);
					}
					,
					cssClass: 'btn-yes'
				},
				{
					text: '',
					cssClass: 'btn-empty'
				},
				{
					text: 'NO',
					handler: () => {
						selected = true;
						callBack(false);
					},
					cssClass: 'btn-no'
				}
			],
			cssClass: "cus-alert-modal"
		});
		confirm.onDidDismiss().then(() => {
			if (!selected) { //In case click outside popup
				callBack(false);
			}
		});
		confirm.present();
	}
	async alertOk(title, message, callback?) {
		let alert = await this.alertCtrl.create({
			header: title,
			message: message,
			backdropDismiss: false,
			buttons: [{
				text: 'OK',
				handler: () => {
					if (callback) {
						callback(true);
					}
				}
			}],
			cssClass: "cus-alert-message cus-btns"
		});
		alert.present();
		return alert;
	}

	async alert(title, message, callback?) {
		let alert = await this.alertCtrl.create({
			header: title,
			message: message,
			buttons: [
				{
					text: 'Yes',
					handler: () => {
						return callback;
					},
					cssClass: 'btn-ok'
				},
				{
					text: 'No',
					role: 'cancel',
					cssClass: 'btn-no',
				}
			],
			cssClass: "rs-alert-yes-no"
		});
		alert.present();
		return alert;
	}

	async alertOkHtml(title, message, textButton, callbackButtonClose?): Promise<HTMLIonAlertElement> {
		if (this.runOnceAlert) {
			console.warn('Block alertOkHtml message');
			return;
		}
		this.runOnceAlert = this.alertCtrl.create({
			header: title,
			message: new IonicSafeString(message),
			cssClass: 'cus-alert-message cus-btns',
			buttons: [
				{
					text: textButton,
					role: 'ok',
					cssClass: 'btn-secondary'
				}/*,
				{
					text: callbackButtonClose ? 'X' : '',
					cssClass: 'btn-close-alert',
					handler: () => {
						if (callbackButtonClose) {
							callbackButtonClose();
						}
					}
				}
				*/
			],
		});
		let alert: HTMLIonAlertElement = await this.runOnceAlert;
		alert.present();
		alert.onDidDismiss().then(result => {
			this.runOnceAlert = null;
		});
		return alert;
	}
	async alertError(message, callback?, textButton?, callbackButtonClose?) {
		await this.hideLoading();
		let alert = await this.alertCtrl.create({
			header: "Oh No!",
			message: message,
			// backdropDismiss: false,
			// buttons: [

			// ],
			cssClass: "cus-alert-message alert-error cus-btns",
			buttons: [
				{
					text: textButton || 'OK',
					handler: () => {
						if (callback) {
							callback();
						}
					}
				},
				{
					text: callbackButtonClose ? 'X' : '',
					cssClass: 'btn-close-alert',
					handler: () => {
						if (callbackButtonClose) {
							callbackButtonClose();
						}
					}
				}
			]
		});

		alert.present();
	}
	async alertSuccess(message, title?) {
		let alert = await this.alertCtrl.create({
			header: 'Success!',
			message: message,
		})
		alert.present();
		// auto dismiss
		setTimeout(() => {
			alert.dismiss();
		}, 5000)
	}
	async alerErrorWithSupportContact(message) {
		var spMsgSetting = await this.appSettings.getSettingValue('SUPPORT_MESSAGE_APP').toPromise();
		let inclueContactInfo = message && message.indexOf('c-support') > -1;
		if (!inclueContactInfo) {
			message = `${message} ${spMsgSetting}`;
		}
		const alert = await this.alertCtrl.create({
			header: 'Oh No!',
			message: message,
			cssClass: 'cus-btns support-contact',
			buttons: [
				{
					text: 'OK',
					handler: () => {
					},
					cssClass: ''
				}
			]
		});
		alert.present().then((com) => {
			let supportBtn = alert.querySelector('.c-support');
			supportBtn.addEventListener('click', () => {
				this.openInSystem(ENV.links.supportContact);
			});
		});
	}
	formatDate(date) {
		var weekday = new Array(7);
		weekday[0] = "Sunday";
		weekday[1] = "Monday";
		weekday[2] = "Tuesday";
		weekday[3] = "Wednesday";
		weekday[4] = "Thursday";
		weekday[5] = "Friday";
		weekday[6] = "Saturday";

		var monthNames = [
			"Jan", "Feb", "Mar",
			"Apr", "May", "Jun", "Jul",
			"Aug", "Sept", "Oct",
			"Nov", "Dec"
		];
		return [weekday[date.getDay()], monthNames[date.getMonth()] + '. ' + date.getDate()]
	}

	/** copy form https://github.com/amarkes/br-mask/blob/master/src/directives/br-mask.ts **/

	/**
	* Responsible for removing special characters
	* @author Antonio Marques <tmowna@gmail.com>
	* @example <caption>this.formatField(string)</caption>
	* @param {string} field
	* @param {string} mask
	* @param {number} size
	* @returns {string} value
	*/
	public formatField(field: string, mask: string, size: number): any {
		if (!size) { size = 99999999999; }
		let boleanoMascara;
		const exp = /\_|\-|\.|\/|\(|\)|\,|\*|\+|\@|\#|\$|\&|\%|\:| /gi;
		const campoSoNumeros = field.toString().replace(exp, '');
		let posicaoCampo = 0;
		let NovoValorCampo = '';
		let TamanhoMascara = campoSoNumeros.length;
		for (let i = 0; i < TamanhoMascara; i++) {
			if (i < size) {
				boleanoMascara = (mask.charAt(i) === '-') || (mask.charAt(i) === '.') || (mask.charAt(i) === '/');
				boleanoMascara = boleanoMascara || mask.charAt(i) === '_';
				boleanoMascara = boleanoMascara || ((mask.charAt(i) === '(') || (mask.charAt(i) === ')') || (mask.charAt(i) === ' '));
				boleanoMascara = boleanoMascara || ((mask.charAt(i) === ',') || (mask.charAt(i) === '*') || (mask.charAt(i) === '+'));
				boleanoMascara = boleanoMascara || ((mask.charAt(i) === '@') || (mask.charAt(i) === '#') || (mask.charAt(i) === ':'));
				boleanoMascara = boleanoMascara || ((mask.charAt(i) === '$') || (mask.charAt(i) === '&') || (mask.charAt(i) === '%'));
				if (boleanoMascara) {
					NovoValorCampo += mask.charAt(i);
					TamanhoMascara++;
				} else {
					NovoValorCampo += campoSoNumeros.charAt(posicaoCampo);
					posicaoCampo++;
				}
			}
		}
		return NovoValorCampo;
	}

	/*
	openInApp(url) {
		if (!url) {
			return null;
		}
		return this.iab.create(url, '_blank', 'location=no,enableViewportScale=yes,closebuttoncaption=Close,toolbarposition=bottom,footer=yes,clearsessioncache=yes,clearcache=yes');
	}
	*/
	navigateByUrl(mobileUrl) {
		if (!mobileUrl) {
			return;
		}
		try {
			if (mobileUrl.indexOf('app://') < 0) {
				this.openInSystem(mobileUrl);
				return;
			}
			mobileUrl = mobileUrl.replace('app:\/\/', '\/');
			this.router.navigateByUrl(decodeURIComponent(mobileUrl));
		} catch (error) {
			console.log("🚀 ~ file: home.ts ~ line 229 ~ HomePage ~ listenDxpComponent ~ error", error)
		}
	}
	openInSystem(url) {
		if (!url) {
			return;
		}
		return this.iab.create(url, '_system');
	}
	openInaAppBrowser(url, opts?) {
		// var option = "location=no,closebuttoncaption=Done,footer=yes,closebuttoncolor=#FFFFFF,enableViewportScale=yes, clearsessioncache=yes,clearcache=yes";
		let option: any = {
			location: 'no',
			closebuttoncaption: 'Done',
			footer: 'yes',
			closebuttoncolor: '#FFFFFF',
			enableViewportScale: 'yes'
		}
		let ref = this.iab.create(url, '_blank', option);
		if (opts && opts['margin-bottom']) {
			ref.on('loadstop').subscribe((event) => {
				ref.insertCSS({ code: "body{margin-bottom: 200px;}" })
			});
		}
		return ref;
	}
	openPopupWindow({ url, title, w, h, checkBlocker }) {
		// Fixes dual-screen position                             Most browsers      Firefox
		const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
		const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

		const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
		const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

		const systemZoom = width / window.screen.availWidth;
		const left = (width - w) / 2 / systemZoom + dualScreenLeft
		const top = (height - h) / 2 / systemZoom + dualScreenTop
		const newWindow = window.open(url, title,
			`
			scrollbars=yes,
			width=${w / systemZoom}, 
			height=${h / systemZoom}, 
			top=${top}, 
			left=${left}
  			`
		)

		if (newWindow.focus) newWindow.focus();
		if (checkBlocker && (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined')) {
			this.alertError("Pop-up Blocker is enabled! Please add this site to your exception list.");
		}
		return newWindow;
	}
	async openPopupIframe({ url, title, w, h, checkBlocker, callback }) {
		const modal = await this.modalController.create({
			component: IframeModal,
			cssClass: 'online-order-modal rs-medium-modal',
			componentProps: {
				url: url,
				title: title,
			},
		});
		modal.onDidDismiss().then(() => {
			callback && callback();
		});
		return await modal.present();
	}

	public async showGuestMsg() {
		const m = await this.modalController.create({
			component: GuestMsg
		});
		await m.present();
	}

	async mappingDynamicLink(webPath: string, search?: string) {
		let dynamicMappingSettingLinks = await this.appSettings.getSettingValue('DYNAMIC_MAPPING_LINKS').toPromise() || "[]";
		dynamicMappingSettingLinks = dynamicMappingSettingLinks ? JSON.parse(dynamicMappingSettingLinks) : [];
		const dynamicMappingCoreLinks = [
			{
				webUrl: 'rs/weeklyad',
				appUrl: 'tabs/weekly-ad',
				conditional: 'includes',
			},
			{
				webUrl: 'rs/my-offers',
				appUrl: 'tabs/deals/offers',
				conditional: 'includes',
			},
			{
				webUrl: 'rs/my-coupons',
				appUrl: 'tabs/deals/coupons',
				conditional: 'includes',
			},
			{
				webUrl: 'rs/endcap',
				webTemplate: 'rs/endcap/{v}',
				appTemplate: 'tabs/products/endcap/{v}',
				conditional: 'includes',
			},
			{
				webUrl: '/rs/recipes',
				webTemplate: '/rs/recipes/{x}/{v}',
				appTemplate: 'tabs/recipe/{v}',
				conditional: 'includes',
			},
			{
				webUrl: '/',
				appUrl: 'tabs/home',
				conditional: 'equal',
			},
		];
		const dynamicMappingLinks = dynamicMappingSettingLinks.concat(dynamicMappingCoreLinks);

		webPath = webPath && webPath.toLowerCase() || '';

		let urlFound = _.find(dynamicMappingLinks, mappingLink => mappingLink.conditional == "includes" ? webPath.includes(mappingLink.webUrl) : webPath == mappingLink.webUrl);
		if (urlFound && urlFound.webTemplate && urlFound.appTemplate) {
			const value = this.getValueTokenFromTemplate(webPath, urlFound.webTemplate);
			let appUrl = urlFound.appTemplate.replace('{v}', value);
			return appUrl;
		}
		// If webPath was not existed on App => return null to Open InAppBrowser
		return urlFound ? urlFound.appUrl : null;
	}

	getValueTokenFromTemplate(str, template) {
		// str: "rs/recipes/4-strand-braided-bread/7438"
		// template: "rs/recipes/4-strand-braided-bread/{v}" => return v = 7438
		str = _.trim(str, '/ ');
		template = _.trim(template, '/ ');
		var sr = template.replace("?", "\\?")
			.replace(/\{[^v]\}/g, ".*?")
			.replace(/\{v\}/g, "(.+)");

		var rex = new RegExp(sr),
			parts = rex.exec(str);

		if (parts) {
			return parts[1];
		}
		return null;
	}

	encodeUriQuery(val, pctEncodeSpaces?) {
		return encodeURIComponent(val).
			replace(/%40/gi, '@').
			replace(/%3A/gi, ':').
			replace(/%24/g, '$').
			replace(/%2C/gi, ',').
			replace(/%20/g, (pctEncodeSpaces ? '%20' : '+'));
	}
	urlResolve(url, base?) {
		var href = url;

		if (this.msie) {
			// Normalize before parse.  Refer Implementation Notes on why this is
			// done in two steps on IE.
			this.urlParsingNode.setAttribute("href", href);
			href = this.urlParsingNode.href;
		}

		this.urlParsingNode.setAttribute('href', href);

		// urlParsingNode provides the UrlUtils interface - http://url.spec.whatwg.org/#urlutils
		return {
			href: this.urlParsingNode.href,
			protocol: this.urlParsingNode.protocol ? this.urlParsingNode.protocol.replace(/:$/, '') : '',
			host: this.urlParsingNode.host,
			search: this.urlParsingNode.search ? this.urlParsingNode.search.replace(/^\?/, '') : '',
			hash: this.urlParsingNode.hash ? this.urlParsingNode.hash.replace(/^#/, '') : '',
			hostname: this.urlParsingNode.hostname,
			port: this.urlParsingNode.port,
			pathname: (this.urlParsingNode.pathname.charAt(0) === '/') ? this.urlParsingNode.pathname : '/' + this.urlParsingNode.pathname
		};
	}
	parseQueryString(query: string) {
		let result = {};
		// if anything starts with ?, # or & remove it
		query = query.trim().replace(/^(\?|#|&)/, '');
		let params = query.split('&');
		for (let i = 0; i < params.length; i += 1) {
			let param = params[i];  // looks something like a=b
			let parts = param.split('=');
			if (parts.length >= 2) {
				let key = decodeURIComponent(parts.shift()!);
				let value = parts.length > 0 ? parts.join('=') : null;
				if (value) {
					result[key] = decodeURIComponent(value);
				}
			}
		}
		return result;
	}

	isDateCorrect(day, month, year) {
		if (month > 11 || day > 31) {
			return false;
		}
		var d = new Date();
		d.setFullYear(year);
		d.setMonth(month);
		d.setDate(day);
		return d.getDate() == day;
	}

	setCountDownClock(data) {
		if (data === 0 && this._countDownObs) {
			this._countDownObs.unsubscribe();
		}
		console.log('setCountDownClock', data);
		this.cache.saveItem('cache-CountDownClock', data);

	}

	getCountDownClock() {
		var key = 'cache-CountDownClock';
		return this.cache.getItem(key)
			.catch(() => {
				console.log("getCountDownClock expired or doesn't exist!");
			});
	}

	countDownfunc() {
		var getCountDownClock = new Promise((resolve, reject) => {
			this.getCountDownClock().then(res => {
				if (res) {
					this._countDownClock = res;
				}
				resolve(1);
			});
		});
		Promise.all([getCountDownClock]).then((values) => {
			console.log("_countDownClock", this._countDownClock);
			if (this._countDownClock && this._countDownClock > 0) {
				this._countDownObs = interval(this._countDownClock).pipe(map((x) => {
					this._countDownClock = this._countDownClock - 1;
				})).subscribe((x) => {
					console.log("_countDownClock subscribe", this._countDownClock);
					this.setCountDownClock(this._countDownClock);
					var date = new Date(this._countDownClock * 1000);
					this._hours = date.getHours() - 7;
					this._minutes = date.getMinutes();
					this._seconds = date.getSeconds();
				});
			}
		});
	}

	convertToPhone(phone) {
		if (!phone) {
			return "";
		}
		let regxFormat1 = new RegExp(/[()](\d{3})[)] (\d{3})-(\d{4})/);
		let regxFormat2 = new RegExp(/(\d{3})-(\d{3})-(\d{4})/);
		if (regxFormat1.test(phone) || regxFormat2.test(phone)) {
			return phone;
		}
		else {
			if (phone.length == 10) {
				return phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
			}
		}
	}

	titleCase(str) {
		var splitStr = str.toLowerCase().split(' ');
		for (var i = 0; i < splitStr.length; i++) {
			// You do not need to check if i is larger than splitStr length, as your for does that for you
			// Assign it back to the array
			splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
		}
		// Directly return the joined string
		return splitStr.join(' ');
	}

	openAppSettings() {
		this.openNativeSettings.open('application_details').then(rs => {
			console.log('rs openNativeSettings', rs);
		}).catch(err => {
			console.log('err openNativeSettings', err);
		});
	}

	// mapCouponV5(couponMap, cats, couponsHistory, couponType) {
	// 	cats = cats != null ? cats : couponMap.Categories;
	// 	// couponMap.primaryCategory = couponMap.primaryCategory == 'Canned and Packaged' ? 'Canned & Packaged' : couponMap.primaryCategory;
	// 	var cat = _.find(cats, function (o) { return o.CategoryName == couponMap.primaryCategory; });
	// 	var couponHistory = _.find(couponsHistory, function (e) {
	// 		return (e.CpnCode == couponMap.loyaltyAccountId && e.RefID == couponMap.loyaltyPromoId)
	// 	});

	// 	var isAdded = couponHistory != null ? true : false;

	// 	var coupon: any = {
	// 		Id: null,
	// 		Categories: null,
	// 		CouponCode: null,
	// 		ExpirationDate: null,
	// 		StartDate: null,
	// 		EndDate: null,
	// 		ReferenceID: null,
	// 		ExternalOfferId: null,
	// 		CouponType: null,
	// 		Disclaimer: null,
	// 		Brand: null,
	// 		Description: null,
	// 		TextSaving: null,
	// 		NumSaving: null,
	// 		ImageFile: null,
	// 		Title: null,
	// 		IsAdded: false,
	// 	};

	// 	coupon.Id = parseInt(couponMap.loyaltyAccountId);
	// 	coupon.Categories = [cat];
	// 	coupon.CouponCode = couponMap.loyaltyAccountId;
	// 	coupon.ExpirationDate = couponMap.endDate;
	// 	coupon.StartDate = couponMap.displayEffDate;
	// 	coupon.EndDate = couponMap.displayEndDate;
	// 	coupon.ReferenceID = couponMap.loyaltyPromoId;
	// 	coupon.ExternalOfferId = couponMap.externalOfferId;
	// 	coupon.CouponType = couponType;
	// 	coupon.Disclaimer = couponMap.verbiageDisclaimer;
	// 	coupon.Brand = couponMap.verbiageBrand;
	// 	coupon.Description = couponMap.discountTypeDesc;
	// 	coupon.TextSaving = couponMap.verbiageTitle;
	// 	coupon.NumSaving = couponMap.discountAmount;
	// 	coupon.ImageFile = couponMap.imageFileName;
	// 	coupon.Title = couponMap.verbiageTitle;
	// 	coupon.IsAdded = isAdded;
	// 	coupon.VerbiageDetails = couponMap.verbiageDetails;
	// 	coupon.OfferTypeDesc = couponMap.offerTypeDesc;
	// 	coupon.Department = cat.CategoryName;
	// 	return coupon;
	// }

	mapCouponV5(couponMap, cats, couponType) {
		cats = cats != null ? cats : couponMap.Categories || couponMap.Cats;
		var cat = _.find(cats, function (o) { return o.CategoryName == couponMap.primaryCategory || (couponMap.Cats != null && o.CategoryName == couponMap.Cats[0].CategoryName) });
		var coupon: any = {
			Id: null,
			Categories: null,
			CouponCode: null,
			ExpirationDate: null,
			StartDate: null,
			EndDate: null,
			ReferenceID: null,
			ExternalOfferId: null,
			CouponType: null,
			Disclaimer: null,
			Brand: null,
			Description: null,
			TextSaving: null,
			NumSaving: null,
			ImageFile: null,
			Title: null,
			IsAdded: false,
		};

		coupon.Id = couponMap.Id || parseInt(couponMap.loyaltyAccountId);
		coupon.Categories = [cat];
		coupon.CouponCode = couponMap.CpnCode || couponMap.loyaltyAccountId;
		coupon.ExpirationDate = couponMap.ExpDate || couponMap.endDate;
		coupon.StartDate = couponMap.StrtDate || couponMap.displayEffDate;
		coupon.EndDate = couponMap.EndDate || couponMap.displayEndDate;
		coupon.ReferenceID = couponMap.RefID || couponMap.loyaltyPromoId;
		coupon.ExternalOfferId = couponMap.ExternalOfferId || couponMap.externalOfferId;
		coupon.CouponType = couponMap.CpnType || couponType;
		coupon.Disclaimer = coupon.CouponType == "DigitalCoupon" ? couponMap.Description : couponMap.Disclaimer || couponMap.verbiageDisclaimer;
		coupon.Brand = couponMap.Brand || couponMap.verbiageBrand;
		coupon.Description = couponMap.Description || couponMap.verbiageDetails;
		coupon.TextSaving = couponMap.Title || couponMap.verbiageTitle;
		coupon.NumSaving = couponMap.NumSaving || couponMap.discountAmount;
		coupon.ImageFile = couponMap.ImgFile || couponMap.imageFileName;
		coupon.Title = couponMap.TextSaving || couponMap.verbiageTitle;
		coupon.IsAdded = couponMap.IsAdded || false;
		coupon.VerbiageDetails = couponMap.Title || couponMap.verbiageDetails;
		coupon.OfferTypeDesc = couponMap.OfferTypeDesc || couponMap.offerTypeDesc;
		coupon.Department = !!cat ? cat.CategoryName || cat : "";
		return coupon;
	}

	mapCouponAddToAccountV5(couponsMap) {

		var coupon: any = {
			Brand: null,
			CouponCode: null,
			CouponType: null,
			Categories: null,
			Description: null,
			ExpirationDate: null,
			Id: null,
			ImageFile: null,
			NumSaving: null,
			RedeemText: null,
			RedeemCost: null,
			ReferenceID: null,
			TextSaving: null,
			Title: null,
			ItemQuantity: null,
			StartDate: null,
			EndDate: null,
			Segment: null,
			Disclaimer: null,
			ExternalOfferId: null,
			ShoppingListId: null,
			MultiShoppingList: null,
			LoadedDate: null,
			IsAdded: false,
		}

		coupon.Brand = couponsMap.Brand;
		coupon.CouponCode = couponsMap.CpnCode;
		coupon.CouponType = couponsMap.CpnType;
		coupon.Categories = couponsMap.Cats;
		coupon.Description = couponsMap.Description;
		coupon.ExpirationDate = couponsMap.ExpDate;
		coupon.Id = couponsMap.Id;
		coupon.ImageFile = couponsMap.ImgFile;
		coupon.NumSaving = couponsMap.NumSaving;
		coupon.RedeemText = couponsMap.RedeemText;
		coupon.RedeemCost = couponsMap.RdmCost;
		coupon.ReferenceID = couponsMap.RefID;
		coupon.TextSaving = couponsMap.TextSaving;
		coupon.Title = couponsMap.Title;
		coupon.ItemQuantity = couponsMap.ItmQty;
		coupon.StartDate = couponsMap.StrtDate;
		coupon.EndDate = couponsMap.EndDate;
		coupon.Segment = couponsMap.Segment;
		coupon.Disclaimer = couponsMap.Disclaimer;
		coupon.ExternalOfferId = couponsMap.ExternalOfferId;
		coupon.ShoppingListId = couponsMap.ShoppingListId ? couponsMap.ShoppingListId : 0;
		coupon.MultiShoppingList = couponsMap.MultiShoppingList;
		coupon.LoadedDate = couponsMap.LoaddedData;
		coupon.IsAdded = couponsMap.IsAdded;

		return coupon;
	}

	sendSupportEmail(obj: any) {
		Object.assign(obj, { BannerID: ENV.DefaultBanerId });
		return this.api.post('/feedbacks', obj)
	}

	rsAddress2ecomAddress(rsAddress) {
		// console.log('rsAddress', rsAddress);
		var address = {
			Id: rsAddress.CS_ContactID,
			AddressLine1: rsAddress.Address,
			AddressLine2: '',
			AddressLine3: '',
			City: rsAddress.City,
			Region: rsAddress.State,
			CountryCode: rsAddress.Country || 'US',
			PostalCode: rsAddress.ZipCode,
			FirstName: rsAddress.FirstName,
			LastName: rsAddress.LastName,
			DeliveryPointId: '',
			NeighborhoodId: '',
			WasAddressValidated: false,
			IsDefaultBilling: rsAddress.IsBilling,
			IsDefaultShipping: rsAddress.IsDelivery,
			PhoneNumbers: []
		};
		if (rsAddress.Phone) {
			address.PhoneNumbers.push({
				IsMobile: false,
				FullNumber: rsAddress.Phone,
				PhoneNumberType: 'Primary',
				FullNumberFormatted: '',
				Description: 'Primary Phone'
			});
		}
		if (rsAddress.MobilePhone) {
			address.PhoneNumbers.push({
				IsMobile: true,
				FullNumber: rsAddress.MobilePhone,
				PhoneNumberType: 'Mobile',
				FullNumberFormatted: '',
				Description: 'Mobile'
			});
		}

		if (rsAddress.SecondaryPhone) {
			address.PhoneNumbers.push({
				IsMobile: false,
				FullNumber: rsAddress.SecondaryPhone,
				PhoneNumberType: 'Secondary',
				FullNumberFormatted: '',
				Description: 'Secondary Phone'
			});
		}


		return address;
	}

	validURL(str) {
		var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
		return !!pattern.test(str);
	}
	openTCLink() {
		this.openInaAppBrowser(ENV.links.tc);
	}

	openPPLink() {
		this.openInaAppBrowser(ENV.links.privacy);
	}
}
export function noop() { };
export function cacheErrorHandle() { return null };
export function refreshParam(refresher, groupkey?, key?, prefixKey?) {
	return { rCache: refresher ? 'fresh' : 'default', rCacheKey: key || null, rCacheGroupKey: groupkey || null, rPrefixCacheKey: prefixKey || null };
}
console.log('prevent');
export function preventBuble(event) {
	if (!event) {
		return;
	}
	if (event.stopPropagation) {
		event.stopPropagation();
	}
	if (event.preventDefault) {
		event.preventDefault();
	}
	if (!event.srcEvent) {
		return;
	}
	let srcEvent = event.srcEvent;
	if (srcEvent.stopPropagation) {
		srcEvent.stopPropagation();
	}
	if (srcEvent.preventDefault) {
		srcEvent.preventDefault();
	}
}

