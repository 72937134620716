import { Injectable } from '@angular/core';
import _ from 'lodash';

// import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { Platform } from '@ionic/angular';
import { RelationshopHttpClient } from '@rsApp/modules/gateway/rs-api.service';
import { Device } from '@ionic-native/device/ngx';
import { ENV } from '@app/env';
import { Utils } from '@rsApp/modules/utils/providers/utils';
import { MatomoTracker, MatomoInjector } from 'ngx-matomo';
import { CacheService } from 'ionic-cache';
import flatten from "lodash/flatten";
import map from "lodash/map";
import groupBy from "lodash/groupBy";
import sumBy from "lodash/sumBy";
import { IAddBagData, IBag, IBagItem, IUpdateBagItemData } from '@rsApp/modules/interfaces/bag.interface';
import { CurrentCustomService } from '@rsApp/modules/online-order/providers/current-custom-service.service';
import { Credential } from '@rsApp/modules/auth/providers/credential.service';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { enumTrackAction } from '../enum/enum';
import { Subject, Subscription } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class RSTracker {
	enumDimension = {
		"MemberID": 1,
		"HomeStore": 2,
	};
	subscriptions: Subscription[] = [];
	constructor(
		public platform: Platform,
		public firebaseX: FirebaseX,
		public api: RelationshopHttpClient,
		public device: Device,
		public matomoInjector: MatomoInjector,
		public matomoTracker: MatomoTracker,
		public cache: CacheService,
		public currentService: CurrentCustomService,
		private cre: Credential,
		public events: NgxPubSubService,
		public utils: Utils) {
		// console.log('Hello EventLoggerProvider Provider with firebase');
	}
	async init() {
		// console.log('inject');
		if (ENV.EnableMatomo) {
			try {
				const cfg = ENV.MatomoCfg;
				const siteId = await this.getSiteIdMatomo(cfg.mId);
				this.cre.getRsSessionId();

				this.matomoTracker.setCustomUrl('http://' + ENV.AppBundle);
				// this.matomoInjector.init(cfg.mUrl, cfg.mId);
				this.initMatomo(cfg.mUrl, siteId);
				this.trackInstallGoal();
				this.registerEvent();
			} catch (error) { }

		}
	}

	registerEvent() {
		//Ecommerce Events
		const obsPurchaseOrder = this.events.subscribe(enumTrackAction.PurchaseOrder, (params: { order: any, shoppingCartItems: any }) => {
			this.handleTrackEcommerceOrder(params);
		});
		this.subscriptions.push(obsPurchaseOrder);

		const obsTrackEcommerceShoppingCart = this.events.subscribe(enumTrackAction.TrackEcommerceShoppingCart, ({ shoppingCart }) => {
			this.handleTrackEcommerceShoppingCart(shoppingCart);
		});
		this.subscriptions.push(obsTrackEcommerceShoppingCart);

		const obsCategoryView = this.events.subscribe(enumTrackAction.CategoryView, ({ category, pageTitle }) => {
			this.trackCategoryView(category, pageTitle);
		});
		this.subscriptions.push(obsCategoryView);

		const obsProductView = this.events.subscribe(enumTrackAction.ProductView, ({ product }) => {
			this.trackProductView(product);
		});
		this.subscriptions.push(obsProductView);

		const obsAddEcommerceItem = this.events.subscribe(enumTrackAction.AddEcommerceItem, ({ product }) => {
			this.addEcommerceItem(product);
		});
		this.subscriptions.push(obsAddEcommerceItem);

		const obsRemoveEcommerceItem = this.events.subscribe(enumTrackAction.RemoveEcommerceItem, ({ product }) => {
			this.removeEcommerceItem(product);
		});
		this.subscriptions.push(obsRemoveEcommerceItem);

		const obsClearEcommerceCart = this.events.subscribe(enumTrackAction.ClearEcommerceCart, ({ isTrackEvent }) => {
			this.clearEcommerceCart(isTrackEvent);
		});
		this.subscriptions.push(obsClearEcommerceCart);

		const obsTrackEcommerceCartUpdate = this.events.subscribe(enumTrackAction.TrackEcommerceCartUpdate, ({ grandTotal }) => {
			this.trackEcommerceCartUpdate(grandTotal);
		});
		this.subscriptions.push(obsTrackEcommerceCartUpdate);

		//Online Order Events
		const obsOnChangeSystemService = this.events.subscribe(enumTrackAction.OnChangeSystemService, ({ systemServiceName }) => {
			this.onChangeSystemService(systemServiceName);
		});
		this.subscriptions.push(obsOnChangeSystemService);

		const obsPurchaseOnlineOrder = this.events.subscribe(enumTrackAction.PurchaseOnlineOrder, (params: { orders: any, shoppingBagItems: any }) => {
			this.handleTrackOnlineOrderOrder(params);
		});
		this.subscriptions.push(obsPurchaseOnlineOrder);

		const obsTrackOnlineOrderShoppingBag = this.events.subscribe(enumTrackAction.TrackOnlineOrderShoppingBag, ({ bag }: { bag: IBag }) => {
			this.handleTrackOnlineOrderShoppingBag(bag);
		});
		this.subscriptions.push(obsTrackOnlineOrderShoppingBag);

		const obsEventAddOnlineOrderItem = this.events.subscribe(enumTrackAction.EventAddOnlineOrderItem, ({ product }) => {
			this.trackEventAddOnlineOrderItem(product);
		});
		this.subscriptions.push(obsEventAddOnlineOrderItem);

		const obsClearOnlineOrderCart = this.events.subscribe(enumTrackAction.ClearOnlineOrderCart, ({ isTrackEvent }) => {
			this.clearOnlineOrderCart(isTrackEvent);
		});
		this.subscriptions.push(obsClearOnlineOrderCart);

		const obsRemoveOnlineOrderItem = this.events.subscribe(enumTrackAction.RemoveOnlineOrderItem, ({ product }) => {
			this.removeOnlineOrderItem(product);
		});
		this.subscriptions.push(obsRemoveOnlineOrderItem);

		const obsOnlineOrderProductView = this.events.subscribe(enumTrackAction.OnlineOrderProductView, ({ product }) => {
			this.trackOnlineOrderProductView(product);
		});
		this.subscriptions.push(obsOnlineOrderProductView);

		// General

		const obsSaveToken = this.events.subscribe(enumTrackAction.SaveToken, ({ options }) => {
			this.saveToken(options).subscribe();
		});
		this.subscriptions.push(obsSaveToken);

		const obsTrackUserProperty = this.events.subscribe(enumTrackAction.TrackUserProperty, ({ name, value }) => {
			this.trackUserProperty(name, value);
		});
		this.subscriptions.push(obsTrackUserProperty);
	}

	handleTrackEcommerceOrder({ shoppingCartItems, order }) {
		try {
			let items = shoppingCartItems || [];
			const srcs = items.map(item => {
				if (!item.InStock || !item.IsAvailable) {
					return Promise.resolve(true);
				}
				return this.addEcommerceItem(item)
			});
			return Promise.all(srcs).then(() => {
				return this.trackEcommerceOrder(order);
			});
		} catch (error) { }
	}
	handleTrackEcommerceShoppingCart(shoppingCart) {
		try {
			let items = shoppingCart.ShoppingCartItems;
			const srcs = items.map(item => {
				if (!item.InStock || !item.IsAvailable) {
					return Promise.resolve(true);
				}
				return this.addEcommerceItem(item)
			});
			return Promise.all(srcs).then(() => {
				return this.trackEcommerceCartUpdate(shoppingCart.TotalAmount);
			});
		} catch (error) { }
	}

	onChangeSystemService(systemServiceName) {
		this.deleteCustomDimension(4);
      	this.clearOnlineOrderCart(false);
      	this.setCustomDimentionServiceType(systemServiceName);
	}
	async handleTrackOnlineOrderOrder({ shoppingBagItems, orders }) {
		try {
			await this.clearOnlineOrderCart(false);
			await this.addOnlineOrderItems(shoppingBagItems);
			orders.map(order => {
				this.trackOnlineOrderOrder(order);
			});
		} catch (e) {
			console.error('trackOnlineOrderOrder', e)
		}
	}
	async handleTrackOnlineOrderShoppingBag(bag: IBag) {
		await this.clearOnlineOrderCart(false);
		await this.addOnlineOrderItems(bag.Items);
		await this.trackOnlineOrderCartUpdate(bag.SubTotal);
	}
	async getSiteIdMatomo(id) {
		if (ENV.DeviceMode != "Web") {
			return id;
		}
		const urlParent = document.referrer.toLowerCase();
		if (urlParent.indexOf("amigos") > -1) {
			id = ENV.MatomoBannerIds['amigos'];
		} else if (urlParent.indexOf("albertsons") > -1) {
			id = ENV.MatomoBannerIds['albertsons'];
		} else if (urlParent.indexOf("marketStreet") > -1) {
			id = ENV.MatomoBannerIds['marketStreet'];
		} else {
			id = ENV.MatomoBannerIds['united'];
		}
		return id;
	}


	initMatomo(url, id) {

		//DU-30: Matomo anti adblock
		window["_paq"] = window["_paq"] || [];
		window["_paq"].push(['trackPageView']);
		window["_paq"].push(['enableLinkTracking']);
		(() => {
			const /** @type {?} */ u = url;
			window["_paq"].push(['setTrackerUrl', u + '1338.php']);
			window["_paq"].push(['setSiteId', id.toString()]);
			const /** @type {?} */ d = document, /** @type {?} */
				g = d.createElement('script'), /** @type {?} */
				s = d.getElementsByTagName('script')[0];
			g.type = 'text/javascript';
			g.async = true;
			g.defer = true;
			g.src = u + '1338.js';
			s.parentNode.insertBefore(g, s);
		})();
	}
	trackEvent(category: string, action: string, name: string, value: any,) {
		if (this.platform.is('cordova')) {
			this.firebaseX.logEvent(name, { pram: value });
			// .then(() => console.log('Event successfully tracked', name, value))
			// .catch(err => console.log('Error tracking event:', name, value, err));
		}

		if (ENV.EnableMatomo) {
			try {
				this.matomoTracker.trackEvent(category, action, name, value)
			} catch (error) { }
		}

	}
	trackUser(userId: any) {
		if (this.platform.is('cordova')) {
			this.firebaseX.setUserId(userId)
				.then(() => {/* console.log('User id successfully set ', userId)*/ })
				.catch(err => {/* console.log('Error setting user id:', userId, err)*/ });
		}

		if (ENV.EnableMatomo) {
			try {
				this.matomoTracker.setUserId(userId);
			} catch (error) { }
		}
	}

	trackUserProperty(name: string, value: any, id?: number) {
		if (this.platform.is('cordova')) {
			this.firebaseX.setUserProperty(name, value + '')
				.then(() => {/*console.log('User property  successfully tracked ', name, value)*/ })
				.catch(err => {/*console.log('User property  tracking event:', name, value, err)*/ });
		}
		if (ENV.EnableMatomo) {
			try {
				this.matomoTracker.setCustomDimension(this.enumDimension[name] || 0, value);
			} catch (error) { }
		}
	}
	trackGoal(id, value?) {
		if (ENV.EnableMatomo) {
			try {
				this.matomoTracker.trackGoal(id, value);
			} catch (error) { }
		}
	}
	trackPage(pageName: any, url?: any) {
		if (this.platform.is('cordova')) {
			this.firebaseX.setScreenName(pageName)
				.then(() => {/*console.log('View successfully tracked ', pageName)*/ })
				.catch(err => {/*console.log('Error tracking view:', pageName, err)*/ });
		}
		if (ENV.EnableMatomo) {
			try {
				url = url || window.location.pathname;
				this.matomoTracker.setCustomUrl('http://' + ENV.AppBundle + url);
				// let serviceType = "";
				// if (url.indexOf('online-order') > -1) {
				// 	serviceType = 'OnlineOrder';
				// }
                console.log("🚀 ~ file: rs-tracker.service.ts ~ line 306 ~ RSTracker ~ trackPage ~ pageName", pageName)
				this.matomoTracker.trackPageView(pageName);
			} catch (error) { }
		}
	}
	trackEcommerceOrder(order) {
		if (!ENV.EnableMatomo || !order) {
			return;
		}
		try {
			const { OrderInfo, OrderResults } = order;

			const { OrderID, OrderTotal, ProductTotal } = OrderResults;
			const groupInvoice = _.reduce(OrderInfo.Invoice, (result, value, key) => {
				result[value.Name] = value;
				return result;
			}, {});
			const pickupCharge = groupInvoice["Pickup Charge"] && groupInvoice["Pickup Charge"].Value || "";
			const deliveryCharge = groupInvoice["Delivery Fee"] && groupInvoice["Delivery Fee"].Value || "";
			const taxTotal = groupInvoice["Tax Total"] && groupInvoice["Tax Total"].Value || "";
			const discountTotal = groupInvoice["Discount Total"] && groupInvoice["Discount Total"].Value || "";

			const revenue = OrderTotal ? Number(OrderTotal.replace(/[^\d.]/g, '')) : 0;
			const subTotal = ProductTotal ? Number(ProductTotal.replace(/[^\d.]/g, '')) : 0;
			const tax = taxTotal ? Number(taxTotal.replace(/[^\d.]/g, '')) : 0;
			const discount = discountTotal ? Number(discountTotal.replace(/[^\d.]/g, '')) : 0;
			const shipping = pickupCharge ? Number(pickupCharge.replace(/[^\d.]/g, '')) : Number(deliveryCharge.replace(/[^\d.]/g, '')) || 0;

			this.matomoTracker.trackEcommerceOrder(OrderID, revenue, subTotal, tax, shipping, discount);
			window["_paq"].push(['trackEvent', "Ecommerce", "CreateOrder", "OrderId", OrderID, { dimension4: 'ShopOnline' }]);
		}
		catch (error) { }
	}
	trackCategoryView(category, pageTitle?) {
		if (!ENV.EnableMatomo || !category) {
			return;
		}
		try {
			// this.matomoTracker.setEcommerceView("false", "false", category);
			window["_paq"].push(['setEcommerceView',
				false,
				false,
				category = category
			]);
			this.matomoTracker.trackPageView(`${pageTitle ? (pageTitle + ' - ') : ''}${category}`); // Calling trackPageView is required when tracking a product view 
		}
		catch (error) { }
	}
	trackProductView(product) {
		if (!ENV.EnableMatomo || !product) {
			return;
		}
		try {
			const { UPC, Name, Category, PriceText, SalePrice, Price } = product;
			const price = SalePrice || Price || 0;
			const itemKey = product.CurrentVariant && product.CurrentVariant.ItemKey || product.ItemKey;
			const productCategory = Category || product.CategoryName || (product.ProductCategories && product.ProductCategories[0] && product.ProductCategories[0].CategoryName || "");
			// console.log('productCategory', productCategory, product);

			this.matomoTracker.setEcommerceView(UPC || itemKey, Name, productCategory, price);
			this.matomoTracker.trackPageView(`ProductDetailPage - ${Name}`); // Calling trackPageView is required when tracking a product view 
		}
		catch (error) { }
	}

	// This adds a product into the cart, and must be called for each product in the cart
	async addEcommerceItem(product) {
		if (!ENV.EnableMatomo || !product) {
			return;
		}
		try {
			const { UPC, ProductVariantName, Name, PriceText, Quantity, SalePrice, Price } = product;
			const { Upc, ItemName, PriceTotal } = product;
			const price = SalePrice || Price || 0;
			const quantity = Quantity || 1;
			const productCategory = product.CategoryName || (product.ProductCategories && product.ProductCategories[0] && product.ProductCategories[0].CategoryName || "");
			// console.log('productCategory', productCategory, product, quantity);
			const sku = product && product.ProductVariant && product.ProductVariant.Sku;
			this.matomoTracker.addEcommerceItem(UPC || sku, Name || ProductVariantName, productCategory, price, quantity);
		}
		catch (error) { }
	}



	removeEcommerceItem(product) {
		if (!ENV.EnableMatomo || !product) {
			return;
		}
		try {
			const { UPC } = product;
			const sku = product && product.ProductVariant && product.ProductVariant.Sku;
			// this.matomoTracker.removeEcommerceItem(ItemKey, Name, productCategory, price, quantity);
			// removeEcommerceItem is unavailable in ngx-matomo
			(<any>window)._paq.push(['removeEcommerceItem', UPC || sku]);
			window["_paq"].push(['trackEvent', "Ecommerce", "RemoveItem", UPC || sku, 0, { dimension3: 'Product', dimension4: 'ShopOnline' }]);
		}
		catch (error) { }
	}
	clearEcommerceCart(isTrackEvent = true) {
		if (ENV.EnableMatomo) {
			try {
				// this.matomoTracker.removeEcommerceItem(ItemKey, Name, productCategory, price, quantity);
				// removeEcommerceItem is unavailable in ngx-matomo
				window["_paq"].push(['clearEcommerceCart']);
				window["_paq"].push(['trackEcommerceCartUpdate', 0]);
				isTrackEvent && window["_paq"].push(['trackEvent', "Ecommerce", "ClearCart", "", "", { dimension4: 'ShopOnline' }]);
			}
			catch (error) { }
		}
	}

	trackEcommerceCartUpdate(grandTotal = 0) {
		if (ENV.EnableMatomo) {
			try {
				this.matomoTracker.trackEcommerceCartUpdate(grandTotal);
			}
			catch (error) { }
		}
	}
	async addOnlineOrderItems(bagItems) {
		if (!bagItems) {
			return;
		}
		const items = [...bagItems];
		const itemsGroup = groupBy(items, item => {
			const { Options, Upc, ItemType, ItemName } = item;
			if (ItemType == "GiftCard") {
				return `${Upc}-${ItemName}`;
			}
			var optionIds = "";
			var optionIdsInChild = "";
			var optionNames = "";
			var upcName = Upc;
			const optionSets = JSON.parse(Options);
			const childoptions = flatten(map(optionSets, 'ChildOptionSets'));
			const optionsInChild = flatten(map(childoptions, 'Options'));

			const options = flatten(map(optionSets, 'Options'));
			optionIds = map(options, option => option.OptionId).join('-');
			optionIdsInChild = map(optionsInChild, option => option.OptionId).join('-');
			optionNames = map(options, 'DisplayName').join('-');
			if (optionIds && optionIds.length > 0) {
				upcName += ('-' + optionIds);
			}
			if (optionIdsInChild && optionIdsInChild.length > 0) {
				upcName += ('-' + optionIdsInChild);
			}
			return upcName;
		})
		const srcs = map(itemsGroup, ((itemGroup, key) => {
			const Quantity = sumBy(itemGroup, 'Quantity');
			if (itemGroup && itemGroup[0] && (itemGroup[0].IsOutOfStock || !itemGroup[0].IsAvailable)) {
				return Promise.resolve(1);
			}
			return this.addOnlineOrderItem({ ...itemGroup[0], Upc: key, Quantity: Quantity });
		}));
		return await Promise.all(srcs);
	}
	async addOnlineOrderItem(product: IBagItem) {
		if (!ENV.EnableMatomo || !product) {
			return;
		}
		try {
			const { Upc, Options, ProductName, ItemName, PriceTotal, Quantity, CollectionName, ItemType } = product;
			const price = PriceTotal || 0;
			const quantity = Quantity || 1;
			const productCategory = product.CategoryNames && product.CategoryNames[0] || "";
			window["_paq"].push(['addEcommerceItem', Upc, ProductName || ItemName, [productCategory, CollectionName], price, quantity]);
		}
		catch (error) { }
	}
	async trackEventAddOnlineOrderItem(product) {
		if (!ENV.EnableMatomo || !product) {
			return;
		}
		try {
			const { Upc, Options, Quantity, ItemType, itemType, ProductType } = product;
			var optionIds = "";
			var optionIdsInChild = "";
			var optionNames = "";
			var upcName = Upc;
			if (Options) {
				const optionSets = JSON.parse(Options);
				const childoptions = flatten(map(optionSets, 'ChildOptionSets'));
				const optionsInChild = flatten(map(childoptions, 'Options'));
				const options = flatten(map(optionSets, 'Options'));
				optionIds = '-' + map(options, option => option.OptionId).join('-');
				optionIdsInChild = map(optionsInChild, option => option.OptionId).join('-');
				optionNames = map(options, 'DisplayName').join('-');
				if (optionIds && optionIds.length > 0) {
					upcName += ('-' + optionIds);
				}
				if (optionIdsInChild && optionIdsInChild.length > 0) {
					upcName += ('-' + optionIdsInChild);
				}
			}
			const quantity = Quantity || 1;
			const serviceType = this.currentService.systemService && this.currentService.systemService.DisplayName || 'OnlineOrder';
			window["_paq"].push(['trackEvent', "Ecommerce", "AddItem", upcName, quantity, { dimension3: ItemType || ProductType || itemType, dimension4: serviceType, dimension5: optionNames }]);
		}
		catch (error) { }
	}
	async clearOnlineOrderCart(isTrackEvent = true) {
		if (ENV.EnableMatomo) {
			try {
				window["_paq"].push(['clearEcommerceCart']);
				window["_paq"].push(['trackEcommerceCartUpdate', 0]);
				const serviceType = this.currentService.systemService && this.currentService.systemService.DisplayName || 'OnlineOrder';
				isTrackEvent && window["_paq"].push(['trackEvent', "Ecommerce", "ClearCart", "", "", { dimension4: serviceType }]);
			}
			catch (error) { }
		}
	}
	removeOnlineOrderItem(product) {
		if (!ENV.EnableMatomo || !product) {
			return;
		}
		try {
			const { Upc, ItemType, ProductType } = product;
			(<any>window)._paq.push(['removeEcommerceItem', Upc]);
			const serviceType = this.currentService.systemService && this.currentService.systemService.DisplayName || 'OnlineOrder';
			window["_paq"].push(['trackEvent', "Ecommerce", "RemoveItem", Upc, 0, { dimension3: ItemType || ProductType, dimension4: serviceType }]);
		}
		catch (error) { }
	}
	async trackOnlineOrderCartUpdate(grandTotal = 0) {
		if (ENV.EnableMatomo) {
			try {
				const serviceType = this.currentService.systemService && this.currentService.systemService.DisplayName || 'OnlineOrder';
				this.matomoTracker.trackEcommerceCartUpdate(grandTotal);
				window["_paq"].push(['trackEvent', "Ecommerce", "CartUpdate", "GrandTotal", grandTotal, { dimension4: serviceType }]);
			}
			catch (error) { }
		}
	}
	trackOnlineOrderProductView(product) {
		if (!ENV.EnableMatomo || !product) {
			return;
		}
		try {
			const { Upc, Name, ProductType } = product;
			const variant = product && product.ProductVariants && product.ProductVariants[0] || {};

			const price = variant && variant.StorePrice && variant.StorePrice.Price;
			const productCategory = product.Categories && product.Categories[0] && product.Categories[0].Name || "";

			const serviceType = this.currentService.systemService && this.currentService.systemService.DisplayName || 'OnlineOrder';
			this.matomoTracker.setEcommerceView(Upc, Name, productCategory, price);
			window["_paq"].push(['trackPageView', `ProductDetailPage - ${Name}`, { dimension3: ProductType, dimension4: serviceType }]);
		}
		catch (error) { }
	}
	trackOnlineOrderOrder(order) {
		if (!ENV.EnableMatomo || !order) {
			return;
		}
		try {
			const { OrderId, OrderTotal, TaxTotal, Subtotal } = order;

			const revenue = OrderTotal || 0;
			const subTotal = Subtotal || 0;
			const tax = TaxTotal || 0;
			const discount = 0;
			const shipping = 0;
			const serviceType = this.currentService.systemService && this.currentService.systemService.DisplayName || 'OnlineOrder';
			this.matomoTracker.trackEcommerceOrder(OrderId, revenue, subTotal, tax, shipping, discount);
			window["_paq"].push(['trackEvent', "Ecommerce", "CreateOrder", "OrderId", OrderId, { dimension4: serviceType }]);

		}
		catch (error) { }
	}
	setCustomDimentionServiceType(serviceType) {
		if (!ENV.EnableMatomo) {
			return;
		}
		try {
			if (serviceType === "Online") {
				serviceType = "ShopOnline";
			}
			window["_paq"].push(['setCustomDimension', 4, serviceType]);
			window["_paq"].push(['trackPageView']);
		}
		catch (error) { }
	}
	deleteCustomDimension(customDimensionId) {
		if (!ENV.EnableMatomo) {
			return;
		}
		try {
			window["_paq"].push(['deleteCustomDimension', customDimensionId]);
		}
		catch (error) { }
	}
	async getCustomDimension(dimentsionId) {
		if (!ENV.EnableMatomo) {
			return;
		}
		try {
			return this.matomoTracker.getCustomDimension(dimentsionId);
		}
		catch (error) { }
	}
	/**
	**/
	saveToken(opts) {
		let obj = {
			Udid: this.device.uuid,
			// UserName: security.getCurrentUserId(),
			AppName: ENV.AppName,
			AppVersion: ENV.AppVersion,
			CompanyId: ENV.CompanyId,
			Platform: this.device.platform,
			PlatformVersion: this.device.version,
			Device: this.device.model,
			Notification: false,
			ApplicationId: ENV.AppBundle,
			DeviceToken: opts.DeviceToken,
			UserName: opts.UserName
		}
		let location: any = this.utils.getCurrentPosition();
		if (location.isLocationOn) {
			Object.assign(obj, { Latitude: location.lat, Longtitude: location.lng });
		}
		return this.api.post('/users-device', obj);
	}

	// another detail trackGoal
	async trackInstallGoal() {
		if (ENV.EnableMatomo) {
			try {
				let appVersion = await this.cache.getItem('app_version').catch(() => { return null }),
					cacheTime = 60 * 60 * 24 * 365; // 365 days = year
				// refresh cache time and save new app version
				this.cache.saveItem('app_version', ENV.AppVersion, null, cacheTime);
				if (appVersion == ENV.AppVersion) {
					return;
				}
				this.trackEvent('System', 'Install', ENV.AppVersion, 1);
			} catch (error) { }
		}

		// this.trackGoal(1, ENV.AppVersion);
		// this.trackEvent('System', 'Install', ENV.AppVersion, 1);
	}

	ngOnDestroy() {
		this.subscriptions.forEach((s) => {
			s.unsubscribe();
		});
	}
}