import { Injectable, NgZone } from '@angular/core';
import { ENV } from '@app/env';

import { ReplaySubject, Observable, from, of } from 'rxjs';
import { switchMap, observeOn, tap, map, take, catchError } from 'rxjs/operators';
import { CacheService } from "ionic-cache";

import { RelationshopHttpClient } from '@rsApp/modules/gateway/rs-api.service';
import { Credential } from './credential.service';
import { CurrentStore } from '@rsApp/modules/store/providers/current-store.service';
import { Network } from '@ionic-native/network/ngx';
import { noop, refreshParam } from '@rsApp/modules/utils/providers/utils';
import { Platform } from '@ionic/angular';
import { PermanentSettings } from '@rsApp/modules/utils/providers/permanent-settings';
// import { FlyBuyService } from '@rsApp/modules/pickup/providers/flybuy.service';
@Injectable({
	providedIn: 'root'
})
export class AuthService {
	// keep currentUser Information
	public authState$: ReplaySubject<any>;
	public underGuestFlow = false;
	// public  authState: Observable<any>;
	constructor(public rsapi: RelationshopHttpClient, public cre: Credential, public cache: CacheService, public cStore: CurrentStore, public network: Network, private platform: Platform, public pSettings: PermanentSettings/*, public flybuySvc: FlyBuyService*/) {
		this.authState$ = <ReplaySubject<String>>new ReplaySubject(1);
		this.cache.setOfflineInvalidate(false);
		this.platform.ready().then(() => {
			if (ENV.DeviceMode === 'Web') {
				//Trigger APP_INITIALIZER
				this.authState$.next(null);
			} else {
				this.init();
			}
		})

	}
	public async init() {
		// setTimeout(()=>{
		// console.log(this.network.type);
		// }, 1000)
		let isOffLine = this.network.type == this.network.Connection.NONE;
		let ready = await this.cache.ready();
		// clear expires
		if (!isOffLine) {
			await this.cache.clearExpired();
		}

		// console.log('ready', ready);
		// load data from cache
		await Promise.all([this.cre.getCurrentUser(), this.cre.getRsApiToken()]);
		let	haveToken = !!this.cre.rsApiToken;
		// if have token, refresh token, refresh user info. It token is not valid: logout
		// console.log('from Cache:', user, token);
		if (isOffLine) {
			this.authState$.next(this.cre.currentUser);
			return;
		}
		if (haveToken) {
			let freshCre: any = await this.validateToken(this.cre.rsApiToken).toPromise().catch(noop);
			// console.log('freshToken:', freshCre)
			if (freshCre) {
				await this.cre.setCurrentUser(freshCre.User);
				await this.cre.setRsApiToken(freshCre.Token)
			}
			else {
				// console.log('invalid Token set Null');
				await this.cre.setCurrentUser(null);
				await this.cre.setRsApiToken(null)
			}
		}
		// dont have token, token is not valid, get New Token Api
		if (!this.cre.rsApiToken) {
			let apiUser: any = await this.requestAccessToken().toPromise().catch(noop);
			// console.log('apiUser', apiUser);
			if (apiUser) {
				await this.cre.setRsApiToken(apiUser.AccessToken);
			}
		}
		// console.log(this.cre);
		this.authState$.next(this.cre.currentUser);
		await this.cStore.init();
	}
	public authState() {
		return this.authState$.asObservable();
	}
	public getCurrentUser() {
		return this.authState();
	}
	public async setCurrentUser(user) {
		this.authState$.next(user);
	}
	
	public requestAccessToken() {
		// console.log('request access token');
		let tokenRequest = {
			Username: ENV.APIUserName,
			Password: ENV.APIPassword
		};
		return this.rsapi.post(`/tokens/${ENV.APIComsumerKey}`, tokenRequest);
	}
	// valite Token , refresh Token
	public validateToken(token): Observable<{ Token?: string, User?: any }> {
		return this.rsapi.post('/tokens/validate', `"${token}"`).pipe(

			// return this.rsapi.post(`/tokens`, `"${token}"`).pipe(
			switchMap((rs: any) => {
				// invalid token
				if (!rs) {
					return of(null);
				}
				// anoymous token
				if (!this.cre.currentUser || rs.Name == 'UnitedApiUser') {
					return of({ Token: rs.AccessToken, User: null });
				}
				// user token
				return this.getUser(this.cre.currentUser.UserID).pipe(
					map((user: any) => {
						return { Token: rs.AccessToken, User: user };
					})
				);;
			}),
			catchError(() => {
				return of(null);
			})
		);
	}

	public login(accountInfo: any): Observable<{ Token?: string, User?: any }> {
		return this.rsapi.post(`/login?un=${accountInfo.username}`, '"' + accountInfo.password + '"').pipe(
			switchMap((res: any) => {
				// login failed
				if (!res.User) {
					// return of({Token: null, User: null, msg: res.Message});
					throw { statusText:res.Message };
				}
				// login sucess
				return this.getUser(res.User.UserID).pipe(
					map((user: any) => {
						return { Token: res.Token, User: user };
					})
				);
			}),
			// update current select store to user
			switchMap((cre: { Token: string, User: any, msg } | null)=>{
				if(!cre || !this.cStore.store){
					return of(cre);
				}
				/*
				 case have cre and have current store,
				 update curent store to current user
				*/
				let user = cre.User,
					store = this.cStore.store;

				user.StoreID = store.CS_StoreID;
				user.ShopPath = store.ShopPath;
		        user.StoreZipCode = store.Zipcode;
		        user.StoreName = store.StoreName;
		        // user.ShopPath = store.ShopPath === "Online" ? "Online" : "InStore";
		        if(!user.ShopPath){
		        	user.ShopPath = 'InStore';
		        }
				return this.rsapi.put(`/users/${user.UserID}`, user).pipe(
					map(()=>{
						return cre;
					}),
					catchError((error:any)=>{
						return of(cre);
					})
				);
			}),
			switchMap((cre: { Token: string, User: any, msg } | null) => {
				// login false
				// if(!cre.User || !cre.Token){
				// 	throw cre.msg;
				// }
				let o1 = this.cre.setCurrentUser(cre.User),
					o2 = this.cre.setRsApiToken(cre.Token),
					o3 = this.cStore.init();
				return from([o1, o2, o3]).pipe(
					switchMap(() => {
						return o3;
					}),
					map(() => {
						this.authState$.next(this.cre.currentUser);
						return cre;
					})
				)
			})
		);
	}

	public async logout() {
		// backup perperment settings
		await this.pSettings.backup();
		await this.cache.clearAll();
		await this.cre.setCurrentUser(null);
		await this.cStore.setStore(null);
		await this.pSettings.restore();
		window.postMessage({ action: 'userLogOut' }, '*');
		// this.flybuySvc.logout();
		// this.cre.setRsApiToken(null)

		//refresh token
		let apiUser: any = await this.requestAccessToken().toPromise().catch(noop);
		if (apiUser) {
			await this.cre.setRsApiToken(apiUser.AccessToken);
		}

		this.authState$.next(null);

		return;
	}

	public getUser(userId) {
		return this.rsapi.get('/users/' + userId).pipe(
			tap((user: any) => {
				/*
				if(user.ExternalCustomerID){
					return of(user);
				}
				return this.updateExternalCustomerID(user);
				*/
				// this.updateExternalCustomerID(user).pipe(take(1)).subscribe();
			}),
		);
	}
	public refreshUser() {
		return this.getUser(this.cre.currentUser.UserID).pipe(
			tap((user: any) => {
				this.cre.setCurrentUser(user);
			})
		);
	}
	/*
	getCardDemographic(userSRCardID, refresher?) {
		let params = {};
		const cacheGroup = 'reward';
		Object.assign(params, refreshParam(refresher, cacheGroup, null));
		return this.rsapi.get(`/cards/${userSRCardID}/demographic`, { params: params });
	}
	
	public updateExternalCustomerID(user) {
		try {
			return this.getCardDemographic(user.SRCardID).pipe(
				map((cardDemographic: any) => {
					if (cardDemographic && cardDemographic.CustomerCode) {
						user.ExternalCustomerID = cardDemographic.CustomerCode + '';
						user.Segments = cardDemographic.Segments;
					}
					return user;
				}));
		} catch (error) {
			return of(null);
		}

	}
	*/

	public sendEmailVerification(username) {
		return this.rsapi.post('/email-verification?sender=' + username + '&bannerId=' + ENV.DefaultBanerId, "");
	}
	public toogleGuestFlow(t) {
		this.underGuestFlow = t;
		// this.cache.saveItem('allow-guest', true,undefined, 3.154e+8); // expired in 10 years;
	}
	public isUnderGuestFlow() {
		return of(this.underGuestFlow);
	}
	// FaceID
	public async getIsFace() {
		let value = await this.cache.getItem('isFace').catch(() => (null));
		if (value === null) {
			value = true;
		}
		return value;
	}
	public setIsFace(value: any) {
		const cacheTime = 60 * 60 * 24 * 60;  // 60 days
		this.cache.saveItem('isFace', value, null, cacheTime);
	}
	//Notification
	public async getNotification() {
		let value = await this.cache.getItem('setting-notification').catch(() => (null));
		if (value === null) {
			value = true;
		}
		return value;
	}
	public async setNotification(value) {
		const cacheTime = 60 * 60 * 24 * 60 ;  // 60 days x100 ~~ setting manual don't need ttl
		this.cache.saveItem('setting-notification', value, null, cacheTime);
	}
}