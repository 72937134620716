export enum enumCouponsType{
    MFGCoupon = 1,
    MFGOffer = 2,
    MFGReward = 3,
    DigitalCoupon = "DigitalCoupon"
}

export enum enumBalanceType{
    Points = 5,
    PointsExpiring = 0,
    Rewards = 8,
    RewardsExpiring = 1008

}

export enum enumProductType{
    AdItems = "AdItems",
    EcomProduct = "EcomProduct"
}

export enum enumDisplayModeCustomService {
    Collections = 'Collections',
    Products = 'Products'
}
export enum enumStatusRespone {
    Success = 'Success',
    Ok = 'Ok',
    Error = 'Error',
}
export enum enumTrackAction {
    //Ecommerce
    PurchaseOrder = 'tracking::PurchaseOrder',
    CategoryView = 'tracking::CategoryView',
    ProductView = 'tracking::ProductView',
    AddEcommerceItem = 'tracking::AddEcommerceItem',
    TrackEcommerceShoppingCart = 'tracking::TrackEcommerceShoppingCart',
    RemoveEcommerceItem = 'tracking::RemoveEcommerceItem',
    ClearEcommerceCart = 'tracking::ClearEcommerceCart',
    TrackEcommerceCartUpdate = 'tracking::TrackEcommerceCartUpdate',
    //Online Order
    OnChangeSystemService = 'tracking::OnChangeSystemService',
    TrackOnlineOrderShoppingBag = 'tracking::TrackOnlineOrderShoppingBag',
    AddOnlineOrderItems = 'tracking::AddOnlineOrderItems',
    EventAddOnlineOrderItem = 'tracking::EventAddOnlineOrderItem',
    ClearOnlineOrderCart = 'tracking::ClearOnlineOrderCart',
    RemoveOnlineOrderItem = 'tracking::RemoveOnlineOrderItem',
    TrackOnlineOrderCartUpdate = 'tracking::TrackOnlineOrderCartUpdate',
    OnlineOrderProductView = 'tracking::OnlineOrderProductView',
    PurchaseOnlineOrder = 'tracking::PurchaseOnlineOrder',
    SetCustomDimentionServiceType = 'tracking::SetCustomDimentionServiceType',
    DeleteCustomDimension = 'tracking::DeleteCustomDimension',
    SaveToken = 'tracking::SaveToken',
    TrackUserProperty = 'tracking::TrackUserProperty',
    TrackGoal = 'tracking::TrackGoal',
    AddPaymentInfo = 'traccking::AddPaymentInfo',
    SearchProduct = 'tracking::ProductSearch',
    InitCheckOut = 'tracking::InitCheckout'
}
export enum enumWidgetCode {
    homepagetop = "Mobile_HomePage_Top",
    homepagebottom = "Mobile_HomePage_Bottom",
}

export enum AccountType {
    Personal = "Personal",
    Business = "Business"
}

export enum Segment{
    NonProfit = 6,
    BusinessChecks = 7
}


export enum enumExpirationType {
    AbsoluteExpire = 1,
    AdaptiveExpire = 2,
    QuantityLimit
}

export enum ActionType {
    AddToCart = 'addToCart',
    AddToList = 'addToList',
    ViewDetail = 'viewDetail',
    ClipOffer = 'clipOffer',
    ChangeCart = 'ChangeCart',
    ChangeQuantity = 'ChangeQuantity',
    ReloadShoppingBag = 'ReloadShoppingBag',
    DoClipOffer = 'DoClipOffer',
    DoChangeQuantity = 'DoChangeQuantity',
    DoViewDetail = 'DoViewDetail',
    DoViewMore = 'DoViewMore',
    DoNavTab = 'DoNavTab',
    EmitList = 'EmitList',
    EmitCart = 'EmitCart',
    ReloadDataMyBigY = 'ReloadDataMyBigY',
    OnDxpComponentChangeShoppingList = 'OnDxpComponentChangeShoppingList',
    OnDxpComponentChangeShoppingCart = 'OnDxpComponentChangeShoppingCart',
    OnDxpComponentShowGuestMessage = 'OnDxpComponentShowGuestMessage',
    OnChangeShoppingList = 'OnChangeShoppingList',
    OnChangeShoppingCart = 'OnChangeShoppingCart',
    LoadShoppingCart = 'LoadShoppingCart',
    OnDxpComponentAddedCoupon = 'OnDxpComponentAddedCoupon',
    OnDxpComponentAddedOffer = 'OnDxpComponentAddedOffer',
    ChangeMenu = 'ChangeMenu',
    OpenHealthApp = 'HealthApp_Open',
    BackHealthApp = 'HealthApp_Back',
    OpenHealthWeb = 'HealthWeb_Open',
    BackHealthWeb = 'HealthWeb_Back',
    OpenPharmacyApp = 'PharmacyApp_Open',
    BackPharmacyApp = 'PharmacyApp_Back',
    OpenPharmacyWeb = 'PharmacyWeb_Open',
    BackPharmacyWeb = 'PharmacyWeb_Back',
    DoCloseModal = 'DoCloseModal',
    DoCloseAllModal = 'DoCloseAllModal',
    DisplayCart = 'ShowCartIcon',
    ShowFilterGS = "ShowFilterGS"
}

export enum WeeklyAdStore {
        ACTIVE_PAGE_PRODUCT_VIEW = "ACTIVE_PAGE_PRODUCT_VIEW",
        ACTIVE_PAGE_OFFERCODE_PRODUCT_VIEW = "ACTIVE_PAGE_OFFERCODE_PRODUCT_VIEW",
        ACTIVE_PAGE_FLYER_VIEW = "ACTIVE_PAGE_FLYER_VIEW",
}