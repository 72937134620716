export enum enumCouponsType {
    MFGCoupon = 1,
    MFGOffer = 2,
    MFGReward = 3
}

export enum enumBalanceType {
    Points = 5,
    PointsExpiring = 0,
    Rewards = 8,
    RewardsExpiring = 1008

}

export enum enumProductType {
    AdItems = 'AdItems',
    EcomProduct = 'EcomProduct'
}

export enum enumDUFBSetting {
    DUFB_Start = 'DUFB_Start',
    DUFB_End = 'DUFB_End',
    DUFB_Active = 'DUFB_Active',
}

export enum enumKitchenAidSetting {
    KitchenAid_End = 'KitchenAid_End',
    KitchenAid_Active = 'KitchenAid_Active',
}

export enum enumStoreId {
    UnitedExpress = 24,
    BigYExpress = 31
}

export enum enumCheckoutMsgSetting {
    CheckoutChangeOrder = 'MI9_APP_CheckOut_Change_Order_Info',
    CheckoutSummaryInfo = 'MI9_CheckOut_Order_Summary_info',
    CheckoutWarningInfo = 'MI9_CheckOut_Warning_Info',
    CheckoutWarningStore = 'MI9_CheckOut_Change_Store_Info'
}


export enum enumVivoSetting {
    ViVo_End = 'ViVo_End',
    ViVo_Start = 'ViVo_Start',
    ViVo_Active = 'ViVo_Active',
}

export enum memberAccountEnum {
    MemberAccount = '999'
}