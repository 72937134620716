import { Component, Input, OnChanges, NgZone, OnInit } from "@angular/core";
import { IWidgetLayout, IWidgetZoneInfo, IZoneInfo } from "@rsApp/modules/interfaces/widget-layout.interface";
import { DxpComponentService } from "../../providers/dxp.component.service";
import { WidgetLayoutService } from "../../providers/widget-layout.service";

@Component({
  selector: "widget-layout",
  templateUrl: "widget-layout.component.html",
  styleUrls: ['widget-layout.component.scss']
})
export class WidgetLayoutComponent implements OnInit, OnChanges {
  @Input() public widgetId?: string;
  @Input() public widgetCode?: string;
  @Input() public type?: string;
  @Input() public objectId?: string;
  @Input() public slug?: string;
  @Input() public zoneName?: string;
  widgetHTML: string = '';
  constructor(private zone: NgZone,
    public widgetLayoutService: WidgetLayoutService,
    public dxpComponentService: DxpComponentService
  ) {
  }
  async ngOnInit() {
    if (this.widgetId) {
      //set
    } else if (this.widgetCode) {

    } else if (this.type) {
      const slug = this.slug && this.slug[0] == '/' ? this.slug.substring(1) : this.slug;
      this.widgetLayoutService.getLayout(slug, this.objectId, this.type).subscribe(async (zoneName: IWidgetLayout) => {
        await this.dxpComponentService.onConnected();
        const zone: IZoneInfo = zoneName && zoneName.ZoneInfoValue && zoneName.ZoneInfoValue.find(s => s.Name == this.zoneName);
        if (!zone) {
          return;
        }
        this.widgetHTML = zone && zone.Widgets && zone.Widgets.map(widget => {

          return widget && widget.Id && `
          <dxp-widget widgetid=${widget.Id} viewmode="Mobile"></dxp-widget>
        ` || '';
        }).join('');
      });
    }
  }

  public ngOnChanges() {

  }


}
