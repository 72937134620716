import { Injectable, Injector } from '@angular/core';
import _ from 'lodash';
import { Utils } from '@rsApp/modules/utils/providers/utils';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ENV } from '@app/env';
import { AppSettings } from './app-setting';
import { ActionType } from '../enum/enum';
@Injectable({
	providedIn: 'root'
})
export class DxpComponentService {
	constructor(
		public utils: Utils,
		public appSettings: AppSettings,
		public events: NgxPubSubService,
		public modalCtrl: ModalController,
		public router: Router
	) {
	}

	loadScriptDxpComponent() {
		try {
			this.appSettings.getSettingValue('DXP_COMPONENT_BUILD_NUMBER_APP').subscribe(_settingBuildNumber => {
				if (!_settingBuildNumber) {
					return;
				}
				let settingBuildNumber = JSON.parse(_settingBuildNumber);
				let buildNumber = settingBuildNumber[ENV.AppVersion] || settingBuildNumber['stable'] || 0;
				buildNumber = Number(buildNumber) || Number(ENV.DxpComponentBuildNumber);
				let node = document.createElement('script'); // creates the script tag
				node.src = `${ENV.ScriptUrl}/dxp-component${buildNumber}/dxp-component.esm.js`; // sets the source (insert url in between quotes)
				// node.src = `http://localhost:3333/build/dxp-component.esm.js`;

				node.type = 'module'; // set the script type
				node.async = true; // makes script run asynchronously
				node.charset = 'utf-8';
				// append to head of document
				document.getElementsByTagName('head')[0].appendChild(node);

				let link = document.createElement('link');
				link.href = `${ENV.ScriptUrl}/dxp-component${buildNumber}/dxp-component.css`;
				// link.href = `http://localhost:3333/build/dxp-component.css`;
				link.rel = 'stylesheet';
				document.getElementsByTagName('head')[0].appendChild(link);
				ENV.DxpComponentBuildNumber = buildNumber.toString();
			});

		} catch (error) {
			console.error('loadScriptDxpComponent error: ', error)
		}
	}
	async initializeDxpComponent({ user, store, cre, currentShoppingList }) {
		//load active shopping list
		// await this.sList._loadActiveShoppingList().toPromise();
		// const currentShoppingList = await this.sList.getActiveList().pipe(take(1)).toPromise();
		customElements.whenDefined('dxp-widget').then(() => {
			// console.log('whenDefined');
			// console.log("🚀 ~ file: dxp.component.service.ts ~ line 76 ~ DxpComponentService ~ customElements.whenDefined ~ store", store)
			// console.log("🚀 ~ file: dxp.component.service.ts ~ line 69 ~ DxpComponentService ~ customElements.whenDefined ~ user", user)
			const data: {
				CurrentUser: any;
				CurrentShopPath: string;
				CurrentDXPStore: any;
				CurrentShoppingList: any;
				CurrentShoppingListId: any;
				CurrentShoppingCart: any;
				ViewMode: string;
				Authorization: string;
				ENV: any;
			} = {
				CurrentUser: user,
				CurrentShopPath: user && user.ShopPath || (store && store.ShopPath) || "InStore",
				CurrentDXPStore: store,
				CurrentShoppingList: currentShoppingList,
				CurrentShoppingListId: currentShoppingList ? currentShoppingList.CS_UserShoppingListId : null,
				CurrentShoppingCart: null,
				ViewMode: "Mobile",
				Authorization: cre ? 'Bearer ' + cre.rsApiToken : '',
				ENV: ENV
			}
			const payload = {

				action: 'initializeDXPComponent',
				data: data,
			};
			//pass data to dxp component
			// console.log('payload: ', JSON.stringify(payload))
			window.postMessage({ ...payload }, '*');
		});
	}
	async onChangeStore(store) {
		customElements.whenDefined('dxp-widget').then(() => {
			const data: {
				CurrentShopPath: string;
				CurrentDXPStore: any;
			} = {
				CurrentShopPath: store.ShopPath,
				CurrentDXPStore: store,
			}
			const payload = {
				action: 'initializeDXPComponent',
				data: data,
			}
			//pass data to dxp component
			window.postMessage({ ...payload }, '*');
		});
	}
	async onLoadShoppingCart() {
		const payload = {
			action: "LoadShoppingCart",
		};
		window.postMessage(payload, `*`);
	}
	async onLoadShoppingList() {
		const payload = {
			action: "OnChangeShoppingList",
		};
		window.postMessage(payload, `*`);
	}

	async onConnected() {
		return customElements.whenDefined('dxp-widget');
	}

	async listenDxpComponent(event) {
		if (event && event.data) {
			try {
				if (!event.data || !event.data.action) {
					return;
				}
				let payload;
				try {
					payload = event.data;
				} catch (ex) {
					console.error(ex);
				}
				// if (payload.action == "DoChangeQuantity") {
				//   const result = await this.doChangeQuantityHandler(payload);
				//   console.log("🚀 ~ file: app.component.ts ~ line 545 ~ AppComponent ~ listenDxpComponent ~ result", result)
				//   window.postMessage({ success: true, callbackId: event.data.callbackId, data: { ShoppingListItems: result } }, `*`);

				// } else 
				if (payload.action == ActionType.DoViewDetail) {
					this.showDetailSaleItemHandler(payload);
				} else if (payload.action == ActionType.DoViewMore) {
					let mobileUrl = payload.data && payload.data.dataSource && payload.data.dataSource.MobileUrl;
					try {
						let paramStrings = payload.data && payload.data.dataSource && payload.data.dataSource.Params || null;
						var params = paramStrings && JSON.parse(paramStrings) || null;
					} catch (error) {
						params = null;
					}
					if (mobileUrl) {
						try {
							if (mobileUrl.indexOf('app://') < 0) {
								this.utils.openInaAppBrowser(mobileUrl);
								return;
							}
							this.closeModal();
							mobileUrl = mobileUrl.replace('app:\/\/', '\/');
							if (!params) {
								this.router.navigateByUrl(decodeURIComponent(mobileUrl));
							} else {
								this.router.navigate([decodeURIComponent(mobileUrl), { ...params }]);
							}
						} catch (error) {
							console.log("🚀 ~ file: home.ts ~ line 229 ~ HomePage ~ listenDxpComponent ~ error", error)
						}
					}
				} else if (payload.action == ActionType.OnDxpComponentChangeShoppingList) {
					this.events.publishEvent('emmit-list', {
						source: "dxp-component",
						reload: true
					});
				} else if (payload.action == ActionType.OnDxpComponentShowGuestMessage) {
					this.utils.showGuestMsg();
				} else if (payload.action == ActionType.OnDxpComponentAddedOffer) {
					this.events.publishEvent('clear-cache-offers', null);
				} else if(payload.action == ActionType.ShowFilterGS){
					this.events.publishEvent('show-filter-gs', payload.data);
				}
			} catch (error) { }
		}
	}
	// NOTE: Not ready to release this feature yet
	async doChangeQuantityHandler(payload) {
		// console.log("🚀 ~ file: app.component.ts ~ line 555 ~ AppComponent ~ doChangeQuantityHandler ~ payload", payload)
		// // refer _update2List base-product-page 
		// if (payload.data && payload.data.currentShopPath == 'InStore') {
		// 	//Handle update Shopping List
		// 	const product = payload.data.dataSource;
		// 	const listItem = product.ShoppingItem;
		// 	product.ShpLstItem = product.ShoppingItem;
		// 	const quantity = payload.data.quantity;
		// 	const productV = product.ProductVariants && product.ProductVariants[0] || product;
		// 	const updateItem = await this.sList.updateItem(productV, quantity).toPromise();
		// 	// product.ShpLstItem = productV.ShpLstItem;
		// 	const sListItems = await this.sList.getItems().toPromise();
		// 	return sListItems;
		// } else {
		// 	//Handle update Shopping Cart
		// 	return {};
		// }
	}
	// NOTE: Not ready to release this feature yet
	async showDetailSaleItemHandler(payload) {
		// //Weekly ad
		// if (payload.data && payload.data.contentType == 3) {
		// 	this.showDetailSaleItem(payload.data.dataSource);
		// } else if (payload.data && payload.data.contentType == 1) {
		// 	//offer
		// 	this.presentModalDealsDetail(payload.data.dataSource);
		// }
	}

	// NOTE: Not ready to release this feature yet
	async presentModalDealsDetail(item, header = "Offer") {
		// let props = {
		// 	detail: item,
		// 	header: header,
		// 	title: item.Title,
		// 	desc: item.VerbiageDetails,
		// 	sub: null
		// }
		// // remove affect timezone
		// if (!item.ExpStr && item.ExpirationDate) {
		// 	item.ExpStr = serverDateStr2LocalDateStr(item.ExpirationDate);
		// }
		// if (header === "Offer") {
		// 	props.title = item.TextSaving;
		// 	props.desc = item.Title;
		// 	props.sub = item.Description;
		// }
		// const modal = await this.modalCtrl.create({
		// 	component: ModalDealsDetail,
		// 	componentProps: props
		// });

		// return await modal.present();
	}

	// NOTE: Not ready to release this feature yet
	async showDetailSaleItem(adItem) {
		// adItem.ShpLstItem = adItem.ShoppingItem;
		// let productV = adItem.productV || adItem;
		// let m;
		// //adItem.IsGroup is sale items
		// if (adItem.IsGroup) {
		// 	m = await this.modalCtrl.create({
		// 		component: SaleItemDetailPage,
		// 		componentProps: {
		// 			product: adItem,
		// 			sListItems: null,
		// 			cartItems: null,
		// 		}
		// 	});
		// } else {
		// 	await this.utils.showLoading();
		// 	let productbyUPC: any;
		// 	// Sometime sale item can't find out UPC product, make sure modal still show screen ss
		// 	productbyUPC = await this.weeklyAd.getSaleItemDetail(adItem.Id).toPromise();
		// 	if (productbyUPC && productbyUPC.IsGroup && productbyUPC.ListUPC.length > 1) {
		// 		productV = productbyUPC;
		// 		productV.IsGroup = productbyUPC.IsGroup && productbyUPC.ListUPC.length > 1;
		// 		m = await this.modalCtrl.create({
		// 			//Sometime Saleitem in list resut have IsGroup = false but when get detail IsGroup = true => Show SaleItemDetailPage modal
		// 			component: SaleItemDetailPage,
		// 			componentProps: {
		// 				product: productV,
		// 				sListItems: null,
		// 				cartItems: null,
		// 			}
		// 		});
		// 		await m.present();
		// 		const rs = await m.onDidDismiss();
		// 		if (rs && rs.data && rs.data.haveChange) {
		// 			adItem.ShpLstItem = productV.ShpLstItem;
		// 			adItem.ShpCartItem = productV.ShpCartItem;
		// 		}
		// 		return;
		// 	}
		// 	productbyUPC = await this.product.getProductByUPC(null, 0, 0, [adItem.UPC]).toPromise();
		// 	console.log("🚀 ~ file: home.ts ~ line 290 ~ HomePage ~ showDetailSaleItem ~ productbyUPC", productbyUPC)
		// 	await this.utils.hideLoading();
		// 	if (!productbyUPC || !productbyUPC.Products || productbyUPC.Products.length === 0) {
		// 		this.utils.alertError(`This product isn't available. Please try again later!`)
		// 		return;
		// 	}
		// 	// productV = productbyUPC.Products[0];
		// 	productV.isLoaded = true;

		// 	adItem.productV = productV;
		// 	if (adItem.ShpLstItem) {
		// 		productV.ShpLstItem = adItem.ShpLstItem;
		// 	}
		// 	if (adItem.ShpCartItem) {
		// 		productV.ShpCartItem = adItem.ShpCartItem;
		// 	}

		// 	m = await this.modalCtrl.create({
		// 		component: ProductDetailPage,
		// 		componentProps: {
		// 			product: productV,
		// 			sListItems: null,
		// 			cartItems: null,
		// 		}
		// 	});
		// }
		// await m.present();
		// const rs = await m.onDidDismiss();
		// if (rs && rs.data && rs.data.haveChange) {
		// 	adItem.ShpLstItem = productV.ShpLstItem;
		// 	adItem.ShpCartItem = productV.ShpCartItem;
		// }
	}
	private async closeModal() {
		const modal = await this.modalCtrl.getTop();
		if (!modal) {
			return;
		}
		return this.modalCtrl.dismiss({ ignoreCallback: true });
	}
}